<template>
    <v-card flat>
        <div>
        <v-container class="fill-height">
            <div class="mb-4" style="width: 100%">
                <!-- <v-card-text> -->
                    <div max-width="600px" style="padding-left: 10px">
                    <v-row align="center">
                        <v-col :md="2" justify-self="center">
                        <v-avatar size="88" style="margin-right: 10px">
                            <img src="@/assets/nonton film five feet apart (2019) subtitle indonesia - indoxxi.mp4[2019-06-15 17-00-43.151].png" alt="John" >
                        </v-avatar>
                        </v-col>
                        <v-col >
                        <v-row align="center" style="padding-left: 20px">
                            <span class="headline font-weight-bold">Budi Sumiyati</span>
                        </v-row>
                        <div style="padding-left: 10px">
                            <span class="title" style="color: #888888">170 Transaction</span>
                        </div>
                        <div>
                            <v-chip
                            class="ma-2"
                            color="#EBFCF7"
                            label
                            text-color="#00D79E">
                            0852-9382-0934
                            </v-chip>
                            <v-chip
                            class="ma-2"
                            color="#EEF5FA"
                            label
                            text-color="#2980B9">
                            budiaye@gmail.com
                            </v-chip>
                        </div>
                        </v-col>
                        <v-col>
                            <v-row>
                                <v-col>
                                    <div class="subtitle-1" style="color: #BEC2C4">Gender</div>
                                    <div class="subtitle-1" style="color: #333333">Male</div>
                                    <div class="subtitle-1 mt-8" style="color: #BEC2C4">Joined Date</div>
                                    <div class="subtitle-1" style="color: #333333">12 Aug 2019</div>
                                </v-col>
                                <v-col>
                                    <div class="subtitle-1" style="color: #BEC2C4">Birthday</div>
                                    <div class="subtitle-1" style="color: #333333">12 Apr 1995 (24)</div>
                                    <div class="subtitle-1 mt-8" style="color: #BEC2C4">Last Transaction</div>
                                    <div class="subtitle-1" style="color: #333333">11 Jun 2019</div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    </div>
                    <hr>
                <!-- </v-card-text> -->
            </div>
            <v-row
            v-for="n in 1"
            :key="n"
            :class="n === 1 ? 'mb-6' : ''"
            no-gutters>
            <v-col style="margin-right: 16px">
                <v-card class="fill-height" :shaped="true" :raised="true" style="padding-top: 20px; padding-bottom: 5px; max-height: 200px">
                    <v-row class="mb-6 align-center" no-gutters>
                        <v-col :md="3" class="text-center">
                            <div class="pa-2">
                            <div class="pa-3 mx-7" style="background: #EBFCF7; width: 68px; height:68px; border-radius: 50%;">
                                <v-icon color="#00D79E" :x-large="true">$vuetify.icons.service</v-icon>
                            </div>
                            <span class="font-weight-bold">{{ totalMember }}</span>
                            <v-spacer></v-spacer>
                            <span class="body-2" style="color: #BEC2C4">Total Member</span>
                            </div>
                        </v-col>
                        <v-col :md="6" >
                            <span class="title" >Top 3 Booked Service</span><br>
                            <div v-for="(item, id) in dataMember" :key="id" class="mt-4" style="min-height: 40px; margin-bottom: 5px">
                                <div style="margin-bottom: 5px">
                                    <span>{{ item.name }}</span>
                                    <span class="float-right">{{ item.fpercentage }}</span>
                                </div>
                                <div v-if="id === 'member'">
                                  <v-progress-linear :color="colorMember[0]" :value="item.percentage"></v-progress-linear>
                                </div>
                                <div v-if="id === 'free'">
                                  <v-progress-linear :color="colorMember[1]" :value="item.percentage"></v-progress-linear>
                                </div>
                            </div>
                        </v-col>
                        <v-col :md="2" style="padding-left: 5px;">
                            <div v-for="(item, id) in dataMember" :key="id" class="text-right" style="height: 40px; margin-bottom: 8px">
                                <span style="color: #00D79E">{{ item.value }}</span>
                                <p class="caption" style="color: #BEC2C4"> Users </p>
                            </div>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col >
                <v-card class="fill-height" :shaped="true" :raised="true" style="padding-top: 20px; padding-bottom: 5px; max-height: 200px">
                    <v-row class="mb-6 align-center" no-gutters>
                        <v-col :md="3" class="text-center">
                            <div class="pa-2">
                            <div class="pa-3 mx-7" style="background: #EBFCF7; width: 68px; height:68px; border-radius: 50%;">
                                <v-icon color="#00D79E" :x-large="true">$vuetify.icons.class</v-icon>
                            </div>
                            <span class="font-weight-bold">{{ totalMember }}</span>
                            <v-spacer></v-spacer>
                            <span class="body-2" style="color: #BEC2C4">Total Member</span>
                            </div>
                        </v-col>
                        <v-col :md="6" >
                            <span class="title" >Top 3 Joined Class</span><br>
                            <div v-for="(item, id) in dataMember" :key="id" class="mt-4" style="min-height: 40px; margin-bottom: 5px">
                                <div style="margin-bottom: 5px">
                                    <span>{{ item.name }}</span>
                                    <span class="float-right">{{ item.fpercentage }}</span>
                                </div>
                                <div v-if="id === 'member'">
                                  <v-progress-linear :color="colorMember[0]" :value="item.percentage"></v-progress-linear>
                                </div>
                                <div v-if="id === 'free'">
                                  <v-progress-linear :color="colorMember[1]" :value="item.percentage"></v-progress-linear>
                                </div>
                            </div>
                        </v-col>
                        <v-col :md="2" style="padding-left: 5px;">
                            <div v-for="(item, id) in dataMember" :key="id" class="text-right" style="height: 40px; margin-bottom: 8px">
                                <span style="color: #00D79E">{{ item.value }}</span>
                                <p class="caption" style="color: #BEC2C4"> Users </p>
                            </div>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            </v-row>
            <v-card class="pa-2" :shaped="true" :raised="true" width="100%">
            <v-card-title style="height: 64px">
                <v-row class="mb-6" no-gutters>
                <v-col :md="6">
                    Member List
                </v-col>
                <!-- <v-col :md="4">
                    <div class="float-right" style="margin-right: 8px">
                    <v-menu v-model="menu1" :close-on-content-click="false" max-width="290" >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                        :value="computedDateFormattedMomentjs"
                        readonly
                        v-on="on"
                        solo
                        background-color="#F5F5F5"
                        style="width: 200px"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="date"
                        @click:date="setDate(0)"
                        range
                    ></v-date-picker>
                    </v-menu>
                    </div>
                </v-col>
                <v-col :md="2">
                    <v-autocomplete
                    v-model="selectCity"
                    :items="dropdown_font"
                    solo
                    label="All City"
                    background-color="#F5F5F5"
                    ></v-autocomplete>
                </v-col> -->
                </v-row>
            </v-card-title>
                <div>
                <!-- <ve-line class="mx-6" :data="dataMemberList" :legend-visible="false" :colors="colorsLine" :settings="lineSettings" :extend="extend" :xAxis="xaxis" :after-config="afterConfig" /> -->
                <v-card class="pa-2" :shaped="true" >
                    <v-card-title>
                    14232 Transaction
                    <v-spacer></v-spacer>
                    <v-btn rounded outlined style="margin-right: 8px"><v-icon left>$vuetify.icons.download</v-icon>Download</v-btn>
                    <v-btn rounded outlined style="margin-right: 8px"><v-icon left>$vuetify.icons.print</v-icon>Print</v-btn>
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                        style="width: 10px;"
                    ></v-text-field>
                    </v-card-title>
                    <v-data-table
                        :headers="headerMemberlist"
                        :items="dataMemberListDetail"
                        :search="search"
                        hide-default-footer
                        @click:row="clickStaff">
                        <template v-slot:header.location="{ header }">
                        <span style="color: #00B082">{{ header.text }}</span>
                        </template>
                        <template v-slot:item.name="{ item }">
                            <v-avatar size="36" style="margin-right: 10px">
                            <img
                                :src="item.photo"
                            >
                            </v-avatar>
                            {{ item.name }}
                        </template>
                        <template v-slot:item.member_status_desc="{ item }">
                            <!-- <div v-if="item.status === 1" style="width: 80px;color: #ffffff; background: transparent linear-gradient(180deg, #FFB144 0%, #FFCB56 100%) 0% 0% no-repeat padding-box; border-radius: 4px;">
                                Member
                            </div>
                            <div v-else style="width: 80px; color: #ffffff; background: #D4D4D4 0% 0% no-repeat padding-box; border-radius: 4px;">
                                Free
                            </div> -->
                            <v-btn v-if="item.member_status_desc === 'FREE'" depressed small style="width: 80px; color: #ffffff; background: #D4D4D4 0% 0% no-repeat padding-box;">{{ item.member_status_desc }}</v-btn>
                            <v-btn v-else depressed small style="width: 80px; color: #ffffff; background: transparent linear-gradient(180deg, #FFB144 0%, #FFCB56 100%) 0% 0% no-repeat padding-box;">{{ item.member_status_desc }}</v-btn>
                        </template>
                        </v-data-table>

                    <v-spacer></v-spacer><br>
                    <v-pagination
                    v-model="page"
                    color="#00B082"
                    :length="total_page"
                    :total-visible="7"
                    circle
                    ></v-pagination>
                </v-card>
                </div>
            </v-card>
        </v-container>
        </div>
        <v-dialog
            v-model="dialog"
            max-width="664"
          >
            <v-card class="pb-5">
              <!-- <v-card-title class="headline">Transaction Detail</v-card-title> -->
              <v-toolbar color="elevation-0">
                <v-toolbar-title class="ml-4 font-weight-bold">Transaction Detail</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-btn icon @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>
              <div class="mx-6">
                <v-chip
                  class="ma-2"
                  color="#D4D4D4"
                  label
                  text-color="#6A6A6A">
                  Order Number: 192839FXS918
                  </v-chip>
                  <v-chip
                  class="ma-2"
                  color="#83D9FF"
                  label
                  text-color="#FFFFFF">
                  Services
                  </v-chip>
                  <div class="ml-2">
                    <v-row>
                      <v-col :md="4">
                        <span class="caption" style="color: #BEC2C4">Booking Date</span>
                        <p class="subtitle-1 font-weight-bold">24 Nov 2019</P>
                      </v-col>
                      <v-col :md="4">
                        <span class="caption" style="color: #BEC2C4">Booking Date</span>
                        <p class="subtitle-1 font-weight-bold">24 Nov 2019</P>
                      </v-col>
                      <v-col :md="4" >
                        <div class="float-right text-right">
                          <span class="caption" style="color: #BEC2C4">Total</span>
                          <p class="headline font-weight-bold" style="color: #4CD137">Rp. 1.230.000</P>
                        </div>
                      </v-col>
                    </v-row>
                    <hr class="mb-4">
                  </div>
                  <div v-for="(item, idx) in dataTransaction" :key="idx" class="ml-2">
                    <v-row>
                      <v-col :md="8">
                        <span class="headline font-weight-bold">{{ item.service }}</span><br>
                        <span class="title" style="color: #00D79E">{{ item.location }}</span><br>
                        <v-avatar size="25">
                            <img src="@/assets/nonton film five feet apart (2019) subtitle indonesia - indoxxi.mp4[2019-06-15 17-00-43.151].png" alt="John" >
                        </v-avatar>
                        <span class="title ml-2" style="color: #6A6A6A">{{ item.staff }}</span>
                      </v-col>
                      <v-col :md="4" class="text-right" align-self="center">
                        <span class="headline font-weight-bold" >{{ item.total }}</span>
                      </v-col>
                    </v-row>
                  </div>
              </div>
            </v-card>
          </v-dialog>
    </v-card>
</template>

<script>
import moment from 'moment'
import echarts from 'echarts/lib/echarts'
import { fetchMemberlist, fetchMember, fetchMemberlistDetail } from '@/api/report-member'

export default {
  name: 'ListreportMember',
  props: ['xaxis', 'extend', 'page', 'total_page'],

  data () {
    return {
      menu1: false,
      menu2: false,
      date: [new Date().toISOString().substr(0, 10), moment(new Date().toISOString().substr(0, 10), 'YYYY-MM-DD').add(6, 'd').format('YYYY-MM-DD')],
      selectCity: '',
      dropdown_font: ['All City', 'Yogyakarta', 'Batam', 'Jakarta'],
      ringSettings: {
        area: true,
        labelMap: {
          sales: 'Sales',
          appointment: 'Appointments'
        },
        offsetY: 70,
        radius: [40, 70],
        label: {
          normal: {
            show: false,
            position: 'center'
          },
          emphasis: {
            show: false,
            textStyle: {
              fontSize: '30',
              fontWeight: 'bold'
            }
          }
        }
      },
      colorsLine: ['#00D79E', '#1DA1F3'],
      lineSettings: {
        area: true,
        labelMap: {
          sales: 'Sales',
          appointment: 'Appointments'
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: '#00D79E'
          }, {
            offset: 1,
            color: '#BEF4E6'
          }])
        }
      },
      value1: [0, 10],
      min: 0,
      max: 24,
      search: '',
      dialog: false,
      dataTransaction: [
        {
          service: 'Haircut - Short',
          location: 'Djemari Salon and Spa - Kaliurang',
          staff: 'Sarwono',
          total: '150.000'
        },
        {
          service: 'Potong Rambut Kribo Ala Si Gundul Sekali Hebat Banget',
          location: 'Djemari Salon and Spa - Kaliurang',
          staff: 'Sarwono',
          total: '150.000'
        },
        {
          service: 'Haircut - Short',
          location: 'Djemari Salon and Spa - Kaliurang',
          staff: 'Sarwono',
          total: '150.000'
        }
      ],
      // from api //////////////////////////////////////////////////////
      // //////////////////////////////////////////////////////////////
      colorsRing: ['#FFB144', '#D4D4D4', '#A1FFD1', '#C5DBFF'],
      dataMember: {},
      dataNewMember: {},
      dataMemberList: {},
      dataMemberListDetail: [],
      totalMember: 0,
      totalNewMember: 0,
      colorMember: ['#FFB144', '#BEC2C4'],
      title: {
        text: 0,
        textStyle: {
          color: '#333333',
          fontSize: '36',
          fontStyle: 'normal'
        },
        x: 'center',
        y: 'center'
      },
      headerMemberlist: [
        {
          text: 'Staff',
          align: 'left',
          value: 'name'
        },
        {
          text: 'Age',
          align: 'center',
          value: 'age'
        },
        {
          text: 'Birthday Date',
          align: 'center',
          value: 'fbirthday'
        },
        {
          text: 'Time Joined',
          align: 'center',
          value: 'fcreated_time'
        },
        {
          text: 'Member Status',
          align: 'center',
          value: 'member_status_desc'
        }
      ]
    }
  },

  mounted () {
    this.getInfoMember()
    this.getMemberList()
    this.getMemberListDetail()
  },

  created () {
    this.formatter = value1 => `${value1}:00`
  },

  computed: {
    computedDateFormattedMomentjs () {
      var startDate = ''
      var endDate = ''
      var ret = ''
      startDate = this.date[0] ? moment(this.date[0]).format('D') : ''
      endDate = this.date[1] ? moment(this.date[1]).format('D MMMM YYYY') : ''
      ret = startDate + ' - ' + endDate
      return ret
    }
  },

  methods: {
    setDate (param) {
      if (param === 0) {
        if (this.date.length > 1) {
          this.menu1 = false
        }
      } else {
        if (this.date2.length > 1) {
          this.menu2 = false
        }
      }
      this.getMemberList()
      this.getMemberListDetail()
    },
    afterConfig (options) {
      options.series[0].symbolSize = 16
      return options
    },
    rowClick (row) {
      this.$router.push('/report/report_merchant/2')
    },

    getInfoMember () {
      // this.listLoading = true
      fetchMember().then((response) => {
        this.dataMember = {
          member: {
            name: 'Member',
            value: response.data.data.all_member.member.value,
            percentage: response.data.data.all_member.member.percentage,
            fpercentage: response.data.data.all_member.member.fpercentage
          },
          free: {
            name: 'Free',
            value: response.data.data.all_member.free.value,
            percentage: response.data.data.all_member.free.percentage,
            fpercentage: response.data.data.all_member.free.fpercentage
          }
        }
        this.totalMember = response.data.data.all_member.total_member
        this.totalNewMember = response.data.data.new_member.total_member
        this.title.text = response.data.data.new_member.total_member
        this.dataNewMember = {
          columns: ['percentage', 'fpercentage'],
          rows: [{ 'percentage': parseInt(response.data.data.new_member.member.percentage), 'fpercentage': response.data.data.new_member.member.percentage, 'name': 'Member' },
            { 'percentage': parseInt(response.data.data.new_member.free.percentage), 'fpercentage': response.data.data.new_member.free.percentage, 'name': 'Free' }]
        }
      }).catch((error) => {
        console.log('rsp', error)
      })
      // this.listLoading = false
    },

    getMemberList () {
      // this.listLoading = true
      var param = {
        start_date: this.date[0],
        end_date: this.date[1]
      }
      fetchMemberlist(param).then((response) => {
        console.log('resp', response)
        var row = []
        response.data.data.map(i => {
          row.push(i)
        })
        this.dataMemberList = {
          columns: ['fdate', 'count_customer'],
          rows: row
        }
      }).catch((error) => {
        console.log('rsp', error)
      })
      // this.listLoading = false
    },

    getMemberListDetail () {
      // this.listLoading = true
      var param = {
        start_date: this.date[0],
        end_date: this.date[1]
      }
      fetchMemberlistDetail(param).then((response) => {
        console.log('resp', response)
        this.dataMemberListDetail = response.data.data
      }).catch((error) => {
        console.log('rsp', error)
      })
      // this.listLoading = false
    },

    clickStaff () {
      this.dialog = true
    }
  }
}
</script>

<style lang="scss">
.mytable .v-table tbody tr:not(:last-child) {
    border-bottom: none;
}
</style>

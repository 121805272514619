import request from '@/utils/request'
// import store from '@/store'
import Vuetify from '@/plugins/vuetify'
var langId = Vuetify.preset.lang.current

export function fetchMember () {
  return request({
    url: langId + '/report/member',
    method: 'get'
    // params: {
    //   per_page: 5,
    //   search_column: 'created_time',
    //   search_text: '2019-01-03',
    //   search_operator: '>='
    // }
  })
}

export function fetchMemberlist (params) {
  return request({
    url: langId + '/report/memberlist',
    method: 'get',
    params: params
  })
}

export function fetchMemberlistDetail (params) {
  return request({
    url: langId + '/report/memberlistdetail',
    method: 'get',
    params: params
  })
}

export function fetchMemberInactive (params) {
  return request({
    url: langId + '/report/inactivemember',
    method: 'get',
    params: params
  })
}

export function fetchActivityMember (params) {
  return request({
    url: langId + '/report/activitymember',
    method: 'get',
    params: params
  })
}

export function fetchLongestInactive (params) {
  return request({
    url: langId + '/report/inactivetoplongestmember',
    method: 'get',
    params: params
  })
}

export function fetchTransactionMember (params) {
  return request({
    url: langId + '/report/transactionmember',
    method: 'get',
    params: params
  })
}
